

import Vue from "vue";
import { Extension, Queue } from "@/types/conf";
import { mapActions, mapGetters } from "vuex";
import * as action from "@/store/actions";

import Top from "@/components/templates/Top.vue";
import IpInfoPanel from "@/components/templates/IpInfoPanel.vue";
import i18n from "@/plugins/i18n";

export default Vue.extend({
  name: "Panel",
  data: () => ({
    clonedItemOptions: {
      group: "items",
    },

    actionList: [] as any[],

    currentPage: 0,
    viewHeight: true,
    itemsPerPage: 1000 ,

    search: "" as string,

    queueListKey: 0
  }),
  components: {
    Top,
    IpInfoPanel
},

  computed: {
    ...mapGetters({
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
      extenList: "panel/extenList",
      queueList: "panel/queueList",
      callTimes: "panel/callTimes",
    }),

    searchByNumber(): string[] {

      return this.extenList.filter((element: Extension) => {
        return element.number?.toString().includes(this.search)
      });
    },

    totalPages(): number {
      return Math.ceil(this.searchByNumber.length / this.itemsPerPage);
    },

    paginate(): any[] {

      if (this.currentPage >= this.totalPages) this.currentPage =  0;

      let index = this.currentPage * this.itemsPerPage;

      return this.searchByNumber.slice(index, index + this.itemsPerPage);
    },
  },
  methods: {
    ...mapActions({
      getActions: "crudService/getDetail",
      stopPanel: "panel/stopPanel",
      deleteItem: "panel/deleteQueueMember",
      addToQueue: "panel/addQueueMember",
      postToken: "token/postToken",
    }),
    setPage(pageNumber: number) {
      this.currentPage = pageNumber;
    },

    color(item: Queue | Extension | any) {
      switch (item.status) {
        case 1: // In use
          return "on-call shake";
        case 2: // Ringing
          return "ringing";
        case 3: // Connected
          return "online";
        case 4: // Hang up
          return "hangup";
        case 5: // Music on Hold
          return "on-hold";
        default: // Disconnected
          return "offline";
      }
    },

    async updateActionList() {
      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push({text: i18n.t("lists.---HANG UP---"), value: "---HANG UP---"});
      arr.push( { text: "Hangup" , value: actionList.hangup }  );

      arr.push({text: i18n.t("lists.---IVRS---"), value: "---IVRS---"});
      for (const key in actionList.ivrs) {      
        arr.push( { text: key , value: actionList.ivrs[key] } );
      }

      arr.push({text: i18n.t("lists.---QUEUES---"), value: "---QUEUES---"});
      for (const key in actionList.queues) {
        arr.push( { text: key , value: actionList.queues[key] } );
      }

      arr.push({text: i18n.t("lists.---INTEGRATIONS---"), value: "---INTEGRATIONS---"});
      for (const key in actionList.integrations) {
        arr.push( { text: key , value: actionList.integrations[key] } );
      }

      arr.push({text: i18n.t("lists.---EXTENSIONS---"), value: "---EXTENSIONS---"});
      for (const key in actionList.extens) {
        arr.push({ text: key , value: actionList.extens[key] } );
      }

      this.actionList = arr;
    }
  },
  

  async mounted() {
    console.log(this.queueList)
    await this.updateActionList();
    this.setPage(0);

    setInterval(() => {
      this.postToken();
    }, 60000)
  },

  beforeRouteLeave(to, from, next) { 
    this.postToken();
    next();
  },
});
