
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import Vue from "vue";
import Top from "@/components/templates/Top.vue";
import RestrictionsCorpDetail from "./RestrictionDetail.vue";
//import RestrictionsCorpDetail from "../restrictions/RestrictionsCorpDetail.vue";
import * as action from "@/store/actions";
export default Vue.extend({
  name: "Restriction",
  data: () => ({
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 3 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    restrictionList:[],
    corpList: [],
    destList: [],
    selectedItem: null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  components: {
    CSV,
    CopyButton,
    Top,
    RestrictionsCorpDetail,
  },
  async mounted() {
    await this.refreshList();
  },
  computed: {
    computedRestrictionList() {
      return this.restrictionList;
    },
    ...mapGetters({
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    ...mapActions({
      getExtensionsList: "crudService/getList",
      getDestinationGroup: "crudService/getList",
      getCorpList: "crudService/getList",
      getRestrictionList: "crudService/getList",
      deleteRestriction: "crudService/deleteObject",
    }),
    closeDelete() {
      this.dialogDelete = false;
    },
    headers() {
      return [
        { text: i18n.t("misc.action"), value: "actions", sortable: false, width: "10%" },
        { text: i18n.t("configuration.corp"), value: "corpId", sortable: false },
        { text: i18n.t("misc.pattern"), value: "pattern" },
      ];
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      console.log(this.selectedItem.id);
      const response = await this.deleteRestriction({
        action: action.RESTRICTION_CORPIN_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.restrictionList];
        this.restrictionList = itemListUpdated.filter(
          (res) => res.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },
    async refreshList() {
      this.loadingList = true;
      this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
      this.restrictionList = await this.getRestrictionList(action.RESTRICTION_CORPIN_CONTROLLER);
      const arrCorpIds = [
        ...this.corpList.map((el) => {
          return { id: el.id, name: el.name };
        }),
      ];
      for (let index = 0; index < this.restrictionList.length; index++) {
          for (let j = 0; j < arrCorpIds.length; j++) {
              if (arrCorpIds[j].id === this.restrictionList[index].corpId) {
                this.restrictionList[index].corpId = arrCorpIds[j].name;
              }
            }
          this.loadingList = false;
        }
      },
  },
  
});
