import { Module } from "vuex";
import axios from "axios";
import store from "@/store/index";
import { tokenHeader } from "./auth";
import * as action from "@/store/actions";
import { ActionTree } from "vuex";
import { CRUDActions, RootState } from "@/types/store/index";

interface ActionID {
  action: string;
  id: number;
}

interface ActionObject {
  action: string;
  obj: any;
}

const actions: ActionTree<CRUDActions, RootState> = {
  /* 
      CRUD Actions List:
       * getList(action)            || Returns: response object
       * getDetail(action, id)      || Returns: response object or falsy value
       * newObject(action, obj)     || Returns: boolean
       * updateObject(action, obj)  || Returns: boolean
       * deleteObject(action, id)   || Returns: boolean
    */
  async getList({ dispatch }, action: string) {
    let response;
    const headers = tokenHeader();
    await axios
      .get(action, { withCredentials: true, headers })
      .then((res) => {
        response = res.data;
      })
      .catch((error) => {
        error.response.data.errors.forEach((err: Error[]) => {
          let arr = Object.keys(err);

          store.dispatch("snackbar/showSnack", {
            color: "error",
            message: "Error: " + err[parseInt(arr[1])],
          });
        });
      });

    if (response) {
      return response;
    }
  },
  async getDetail({ dispatch }, { action, id }: ActionID) {
    let response;
    let status;
    const headers = tokenHeader();
    await axios
      .get(action + "/" + id, { withCredentials: true, headers })
      .then((res) => {
        response = res.data;
        status = res.status;
      })
      .catch((error) => {
        error.response.data.errors.forEach((err: Error[]) => {
          let arr = Object.keys(err);

          store.dispatch("snackbar/showSnack", {
            color: "error",
            message: "Error: " + err[parseInt(arr[1])],
          });
        });

        status = error.status;
      });

    if (!!status && status < 300) {
      return response;
    } else {
      return false;
    }
  },
  async newObject({ dispatch }, { action, obj }: ActionObject) {
    let response;
    let status;
    const headers = tokenHeader();
    await axios
      .post(action, obj, { withCredentials: true, headers })
      .then((res) => {
        response = res.data;
        status = res.status;
      })
      .catch((error) => {
        status = error.response.status ;   
        console.log(error.response);
        return status;        
      });
     
    if (!!status && status < 300) {
      return true;
    } else {
      return status;
    }
  },
  
  async updateObject({ dispatch }, { action, obj }: ActionObject) {
    let response;
    let status;
    const headers = tokenHeader();

    await axios
      .put(action, obj, { withCredentials: true, headers })
      .then((res) => {
        response = res.data;
        status = res.status;
      })
      .catch((error) => {
       /* error.response.data.errors.forEach((err: Error[]) => {
          let arr = Object.keys(err);

          store.dispatch("snackbar/showSnack", {
            color: "error",
            message: "Error: " + err[parseInt(arr[1])],
          });
        });*/        
        status = error.response.status ;   
        return status;
      });

    if (!!status && status < 300) {
      return true;
    } else {
      return status;
    }
  },

  async deleteObject({ dispatch }, { action, id }: ActionID) {
    const headers = tokenHeader();
    
    let status;

    await axios.delete(action + "/" + id, { withCredentials: true, headers })
    .then((res) => status = res.status)
    .catch((error) => status = error.response.status);
    
    return status;
  },
  
  async deleteObjectWithData({ dispatch }, { action, id }: ActionID) {
    const headers = tokenHeader();
    
    let response = {
      status: 0,
      data: ""
    };

    await axios.delete(action + "/" + id, { withCredentials: true, headers })
    .then((res) => response.status = res.status)
    .catch((error) => {
      response.status = error.response.status
      response.data = error.response.data
    } );

    console.log(response);
    
    
    return response;
  },

  async getCDRList({ dispatch }, body: any) {
    let response;
    let status;
    const headers = tokenHeader();
    await axios
      .post(action.GET_CDR_LIST, body, { withCredentials: true, headers })
      .then((res) => {
        response = res.data;
        status = res.status;
      })
      .catch((error) => {
        status = error.status;

        store.dispatch("snackbar/showSnack", {
          color: "error",
          message: error,
        });
      });

    if (!!status && status < 300) {
      return response;
    } else {
      return false;
    }
  },
  async getQueueStats({ dispatch }, body: any) {
    let response;
    let status;
    const headers = tokenHeader();
    await axios
      .post(action.GET_LIST_STATISTICS_QUEUE, body, {
        withCredentials: true,
        headers,
      })
      .then((res) => {
        response = res.data;
        status = res.status;
      })
      .catch((error) => {
        status = error.status;

        error.response.data.errors.forEach((err: Error[]) => {
          let arr = Object.keys(err);

          store.dispatch("snackbar/showSnack", {
            color: "error",
            message: "Error: " + err[parseInt(arr[1])],
          });
        });
      });

    if (!!status && status < 300) {
      return response;
    } else {
      return false;
    }
  },
};

export const crudService: Module<CRUDActions, RootState> = {
  namespaced: true,
  actions,
};
