
import Vue from 'vue';
import axios from 'axios';
import Top from '@/components/templates/Top.vue';
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import * as action from "@/store/actions";
import { User,Meet } from "@/types/conf";
import CalendarDetail from './CalendarDetail.vue'
import InfoMeet from './InfoMeet.vue'
export default Vue.extend({
  name: 'Calendar',
  components: {
    Top,
    CalendarDetail,
  },
  data() {
    return {
      firstDayOfCurrentMonth: new Date(),
      newEvent: true,
      showForm: false,
      dialog: false,
      selectedEvent: null,
      isToNew: true, // Asume nueva reunión por defecto
      selected: false,
      users: [] as User[],
      option: null,
      calendarValue: new Date().toISOString().split('T')[0], // Valor inicial del calendario
      events: [] as Meet[],
      selectedDate: new Date()
    };
  },
  watch: {
    selectedUsers(newVal) {
      this.newMeeting.selectedUsers = newVal;
    },
  },
  computed: {
    ...mapGetters({
      corp: 'auth/corp',
      user: 'auth/user',
      softwareId: 'auth/softwareId',
      menuIconColor: "lookAndFeel/menuIconColor",
    }),
  },
  async mounted() {
    this.meetList();
  },
  methods: {
    async meetList() {
      this.firstDayOfCurrentMonth = new Date(this.firstDayOfCurrentMonth)
      var lastMonthDate = new Date(this.firstDayOfCurrentMonth.getFullYear(), this.firstDayOfCurrentMonth.getMonth() - 1, 1);
      const response = await axios.post("http://177.11.49.135:4000/DateRooms/rooms-from-date", {
        corpId: this.corp.id,
        softwareId: this.softwareId,
        userId: this.user.nick,
        date: lastMonthDate
      });
      const events = [];
      for (const room of response.data) {
        const listUser = await this.getUserList(room.name);
        const toDateObj = moment.utc(room.toDate);
        const fromDateObj = moment.utc(room.fromDate);
        const date = toDateObj.format('YYYY-MM-DD');
        const startTime = fromDateObj.format('HH:mm');
        const endTime = toDateObj.format('HH:mm');
        events.push({
          id: room.id,
          name: room.name,
          date: date,
          start: `${date}T${startTime}`,
          end: `${date}T${endTime}`,
          src: room.src,
          userOption: room.userOption,
          selectedUsers: listUser,
          color: this.getColor(date, startTime),
          creator: room.creator,
          topic: room.topic
        });
      }
      this.showForm = false;
      this.events = events;
    },
    async getUserList(name: string) {
      const responseA = await axios.post("http://177.11.49.135:4000/DateRooms/date-rooms", {
        name: name,
        corpId: this.corp.id,
        softwareId: this.softwareId
      });
      return responseA.data;
    },
    getColor(date, startTime) {
      const colors = ['#3F51B5', '#5C6BC0', '#9C27B0', '#AB47BC'];
      const redColor = '#FF0000';
      const now = new Date();
      const currentDate = now.toISOString().split('T')[0];
      const currentTime = now.getTime();
      const isToday = date === currentDate;
      const startTimeDate = new Date(`${date}T${startTime}`);
      const startTimeInMillis = startTimeDate.getTime();
      const timeDifference = startTimeInMillis - currentTime;
      const lessThan30Minutes = timeDifference <= 30 * 60 * 1000 && timeDifference > 0;
      if (isToday && lessThan30Minutes) {
        return redColor;
      }
      return colors[Math.floor(Math.random() * colors.length)];
    },
    async openDay(events) {
      const selectedDate = events.date; 
      this.$router.push({ name: 'dayCalendar', params: { date: selectedDate } });
    },
    async onEventClick(event) {
      this.selectedEvent = event.eventParsed.input;
      this.dialog = true;
      this.$router.push({
        name: 'InfoMeet',
        params: { selectedEvent: event.eventParsed.input, route:"calendar"},
      });
    },
    closeForm() {
      this.newEvent = true
      this.showForm = false; // Ocultar el formulario y mostrar el calendario
    },
    async previousMonth() {
      const calendar = this.$refs.calendar;
      if (calendar) {
        await calendar.prev();
        this.firstDayOfCurrentMonth = calendar.value
        await this.meetList();
      }
    },
    async nextMonth() {
      const calendar = this.$refs.calendar;
      if (calendar) {
        await calendar.next();
        this.firstDayOfCurrentMonth = calendar.value
        await this.meetList();
      }
    },
    updateCalendarValue(value) {
      this.calendarValue = value;
    },
  },
});
