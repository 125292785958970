
import { mapGetters } from 'vuex';
import Vue from 'vue';
import config from '@/config.js';
import {
  setCookie,
} from '@/plugins/utils/cookieManager';

export default Vue.extend({
  name: "DashboardListItem",
  props: {
    item: String,
    dashboardActive: Boolean,
    icon: String,
    text: String,
    subItems: {
      type: Array as () => string[], // Define el tipo como un array de strings
      default: () => []
    },
    computedHover: String,
    isList: {
      type: Boolean,
      default: false
    },
    iconActive: Boolean,
    showExitIcon: {
      type: Boolean,
    },
    activeList: String,
  },
  data() {
    return {
      isVisible: true,
      showSubList: false, // Controlar la visibilidad de la sublista
      activeSubItem: null, // Para rastrear qué sub-item está activo
    };
  },
  watch: {
    activeList(newVal) {
      this.showSubList = newVal === this.item;
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      corp: 'auth/corp',
      softwareId: 'auth/softwareId',
      rooms: 'auth/rooms',
      hoverColor: 'lookAndFeel/hoverColor',
      menuIconColor: 'lookAndFeel/menuIconColor',
      menuFontColor: 'lookAndFeel/menuFontColor',
      loginBottomImage: 'lookAndFeel/loginBottomImage',
      menuColor: 'lookAndFeel/menuColor',
    }),
  },
  methods: {
    async switchCorp(id: number, name: string) {
      if (id == 0) this.isVisible = true;

      setCookie('selectedCorpId', id.toString(), 1);
      setCookie('selectedCorpName', name, 1);

      this.navigateTo('home');
    },
    handleExit() {
      this.switchCorp(0, '');
      this.isVisible = false; // Oculta el componente al hacer clic en el icono de salir
    },
    toggleSubList() {
      this.$emit("update:activeList", this.item);
      if (this.isList) {
        this.showSubList = !this.showSubList;
          } else {
            switch (this.item) {
              case 'logmon':
                this.mini = screen.width < 600;
                this.logmonActive = screen.width > 600;
                window.open(config.logmon45, '_blank');
                break;
              case 'fail2ban':
                this.mini = screen.width < 600;
                this.logmonActive = screen.width > 600;
                window.open(config.fail2ban, '_blank');
                break;
              default:
                this.navigateTo(this.item);
            }
          }
    },
    selectSubItem(subItem: string) {
      switch (subItem) {
        case 'misc.rooms':
          this.mini = screen.width < 600;
          this.salasActive = screen.width > 600;
          this.navigateTo("salas");
          break;
        case 'misc.meetsList':
          this.mini = screen.width < 600;
          this.dashboardActive = screen.width > 600;
          this.navigateTo("calendar");
          break;
        case 'configuration.sipRoutes':
          this.navigateTo("siproutes");
          break;
        case 'configuration.server':
          this.navigateTo("servers");
          break;
        case 'configuration.mediaServer':
          this.navigateTo("mediaserver");
          break;
        default:
          this.navigateTo(subItem.includes('.') ? subItem.split('.')[1] : subItem);
          break;
      }
      this.activeSubItem = subItem;
      this.$emit("select-sub-item", subItem);
    },
    navigateTo(navTo: string) {
      if (navTo !== this.$route.name) {
        this.$router.replace({
          name: navTo,
          params: {
            corp: localStorage.getItem('corp') || '',
            lang: this.$i18n.locale,
          },
        });
      }
    },
  },
});
