
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import Top from "@/components/templates/Top.vue";
import * as action from "@/store/actions";


export default Vue.extend({
	name: 'Webphone',
	components: {
    Top
  },
  data() {
		return {
			selectedExtension: null,
			extensions: [], // Lista de extensiones
			extenlist: [],
			menuIconColor: "",
			menuFontColor: "",
		};
	},
	async mounted() {
		this.extenList = await this.getExtensionsList(action.EXTENSION_CONTROLLER);
		this.extensions = this.extenList
		.filter(ext => ext.portType === "WSS")
		.map(ext => ext.number);
  	},
	computed: {
		...mapGetters({
			user: "auth/user",
			views: "auth/views",
			menuIconColor: "lookAndFeel/menuIconColor",
			menuFontColor: "lookAndFeel/menuFontColor"
		}),
		webphoneUrl() {
			const exten = this.extenList.find(ext => ext.number === this.selectedExtension) || null;
			const domain = window.location.hostname;
			return `https://hey.voipgroup.com:4443?sipUser=${exten.corpId}_${exten.number}&sipServer=${domain}&port=4443&sipPassword=${exten.pass}&action=register&iconcolor=${this.menuIconColor}&fontcolor=${this.menuFontColor}`;
			}
		},
		//return `https://hey.voipgroup.com:4443?sipUser=${exten.corpId}_${exten.number}&sipServer=${domain}&port=4443&sipPassword=${exten.pass}&action=register&iconcolor=${this.menuIconColor}&fontcolor=${this.menuFontColor}`;


	methods: {
		...mapActions({
      getExtensionsList: "crudService/getList",
    }),
		async refreshList() {
      this.extenList = await this.getExtensionsList(action.EXTENSION_CONTROLLER);
		  this.extensions = this.extenList.map(ext => ext.number);
    }
	}
})
