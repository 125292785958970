import i18n from "@/plugins/i18n";

export default function msgCodes(response: number): any {
  switch (response) {
    case 500:
      return i18n.t('misc.invalidTrunkNumber');
    case 501:
      return i18n.t('misc.invalidUsername');
    case 551:
      return i18n.t('misc.extensionExists');
    case 400:
      return i18n.t('misc.extensionTooLong');
    case 550:
      return i18n.t('misc.extensionLimitReached');
    default:
      return i18n.t('misc.defaultError');
  }
}
