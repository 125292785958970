

import Vue from "vue";
import Top from "@/components/templates/Top.vue";

import msgCodes from "@/extendedFunctions/msgCodes";

import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
import { Corp, Server, Trunk } from "@/types/conf";
import { getCookie, setCookie } from '@/plugins/utils/cookieManager';
export default Vue.extend({
  name: "TrunksDetail",
  props: ["trunkToUpdate", "isToEdit", "isToNew", "actionList", "updateActionList"],
  data: () => ({
    dialog: false,
    
    alertType: "",
    alertMessage: "",
    valid: false,
    showPass: false,

    newTrunk: {
      number: "",
      pass: "",
      serId: null,
      corpId: null,
      enabled: null,
      action: "",
      outgoing: null,
      preference: false,
      registerTrunk: true,
      outboundAuth:"",
      outboundAuthRegister: false,
      fromDomain:"",
      fromDomainRegister: false,
    } as Trunk,
    trunkAction: "",

    exceptionList: [
      "---HANG UP---",
      "---IVRS---",
      "---QUEUES---",
      "---INTEGRATIONS---",
      "---EXTENSIONS---",
      "---TIMES---",
    ],
    serverList: [] as Server[],
    corpList: [] as Corp[],

    rules: {
      required: (v: any) => !!v || i18n.t("loginView.required"),
    },
    isNew: false,
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
      menuIconColor: "lookAndFeel/menuIconColor",
    }),
    passwordRulesComputed() {
      // Devuelve reglas solo si registerTrunk está marcado
      return this.newTrunk.registerTrunk ? [this.passwordRules, this.passwordRegexRules] : [];
    },
    color(): string {
      return ["error", "warning", "success"][Math.floor(this.progress() / 40)];
    },
    computedActions(): { text: string; value: string; disabled: boolean; }[] {
      return this.actionList.map((item: any) => {
        return {
          text:  item.text,        
          value: item.value,  
          disabled: this.exceptionList.includes(item.value),
        };
      });
    },
  },
  components: {
      Top
    },
  methods: {
    ...mapActions({
      getServerList: "crudService/getList",
      getActions: "crudService/getDetail",
      getCorpList: "crudService/getList",
      createNewTrunk: "crudService/newObject",
      updateTrunk: "crudService/updateObject",
      updateTrunkAction: "crudService/updateObject",
    }),
    setOutboundAuth() {
      if (this.newTrunk.outboundAuthRegister && !this.newTrunk.outboundAuth) {
        this.newTrunk.outboundAuth = this.newTrunk.number;
      }
    },
    progress(): number {
      return Math.min(100, this.newTrunk.pass.length * 10);
    },

    numRules(text: string) {
      return text.match(/^[0-9]+$/i) ? true : i18n.t("rules.alphanum");
    },
    passwordRules(pass: string) {
      return pass.length > 0 ? true : i18n.t("loginView.msgPass");
    },
    passwordRegexRules(pass: string) {
      return pass.match(/^[a-zA-Z0-9_+*.@ -]*$/)
        ? true
        : i18n.t("rules.alphanumAndSpecial");
    },
    trunkDetailHandler(trunk: any) {
      this.newTrunk.number = trunk.number;
      this.newTrunk.pass = trunk.pass;
      this.newTrunk.serId = trunk.serId;      
      this.newTrunk.corpId = trunk.corpId;
      this.newTrunk.outgoing = trunk.outgoing == i18n.t("misc.yes") ? true : false;
      this.newTrunk.enabled = trunk.enabled == i18n.t("misc.yes") ? true : false;
      this.newTrunk.registerTrunk = trunk.registerTrunk == i18n.t("misc.yes") ? true : false;
      this.newTrunk.outboundAuthRegister = trunk.outboundAuthRegister == 1 ? true : false;
      this.newTrunk.outboundAuth = trunk.outboundAuth;
      this.newTrunk.fromDomainRegister = trunk.fromDomainRegister == 1 ? true : false;
      this.newTrunk.fromDomain = trunk.fromDomainRegister === 1 ? trunk.fromDomain : null;
      if(this.user.level == 1) this.newTrunk.action = trunk.action; 
      else this.newTrunk.action = this.actionList.find((e: any) => e.text == trunk.action)?.value ;

      this.newTrunk.preference = trunk.preference == 1 ? true : false;
    },
    
    async validate() {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
          let newTrunk = {
            id: null as any,
            number: this.newTrunk.number,
            pass: this.newTrunk.pass,
            serId: this.newTrunk.serId,
            corpId: this.newTrunk.corpId,
            outgoing: this.newTrunk.outgoing ? 1 : 0,
            enabled: this.newTrunk.enabled ? 1 : 0,
            action: this.newTrunk.action,
            preference: this.newTrunk.preference ? 1 : 0,
            registerTrunk: this.newTrunk.registerTrunk ? 1 : 0,
            outboundAuth: this.newTrunk.outboundAuthRegister ? this.newTrunk.outboundAuth : null, 
            outboundAuthRegister: this.newTrunk.outboundAuthRegister ? 1 : 0,
            fromDomain: this.newTrunk.fromDomainRegister ? this.newTrunk.fromDomain : null, 
            fromDomainRegister: this.newTrunk.fromDomainRegister ? 1 : 0,
          };
        let response;
        if (newTrunk.outboundAuthRegister == 1) {
          if (!newTrunk.outboundAuth || !/^\d+$/.test(newTrunk.outboundAuth)) {
            this.$store.dispatch("snackbar/showSnack", {
              message: this.$t("rules.num"),
              color: "error",
              timeout: 3000,
            });
            return; 
          }
        }
        if (this.user.level == 1) {
          if (this.isNew) {
            response = await this.createNewTrunk({
              action: action.TRUNK_CONTROLLER,
              obj: newTrunk,
            });
          } else {
            newTrunk.id = this.trunkToUpdate.id;
            response = await this.updateTrunk({
              action: action.TRUNK_CONTROLLER,
              obj: newTrunk,
            });
          }
        } else {
          response = await this.updateTrunkAction({
            action: action.UPDATE_TRUNK_ACTION,
            obj: {
              id: this.trunkToUpdate.id,
              Action: this.newTrunk.action,
            },
          });
        }

        this.dialog = response >= 400;

        this.alertMessage = msgCodes(response, this.isNew).alertMessage;
        this.alertType = msgCodes(response, this.isNew).alertType;

        this.$store.dispatch("snackbar/showSnack", {
          message: this.alertMessage,
          color: this.alertType,
          timeout: 3000,
        });

        this.$emit("refreshList", this.dialog);
      }
    },
  },
  watch: {
    '$i18n.locale': {
      handler: function(newLocale, oldLocale) {
        this.$emit("updateActionList")
      },
      immediate: true
    },
    "newTrunk.outboundAuthRegister"(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.newTrunk.outboundAuth = this.newTrunk.number;
      } else if (!newValue && oldValue) {
        this.newTrunk.outboundAuth = "";
      }
    },
    "newTrunk.fromDomainRegister"(newValue, oldValue) {
       if(!newValue) {
        this.newTrunk.fromDomain = "";
      }
    },
  },

  async mounted() {
    this.isNew = this.trunkToUpdate == null || this.trunkToUpdate == "undefined";

    this.serverList = await this.getServerList(action.SERVER_CONTROLLER);
    this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
    const cookie = getCookie("selectedCorpId")
    if(cookie != null && this.user.corpId != cookie) this.corpList = this.corpList.filter(corp => corp.id == cookie);
    if (!this.isNew) this.trunkDetailHandler(this.trunkToUpdate);
  },
});
