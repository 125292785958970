

  import Vue from "vue";
  
  import msgCodes from "@/extendedFunctions/msgCodes";
  import { mapActions } from "vuex";
  import i18n from "@/plugins/i18n";
  import * as action from "@/store/actions";
  import { Corp, Extension } from "@/types/conf";
  import store from "@/store/index";
  
  export default Vue.extend({
    name: "RestrictionsExtenDetail",
    props: ["resToUpdate", "exten", "isToEdit", "isToNew"],
    data: () => ({
      dialog: false,
  
      alertType: "",
      alertMessage: "",
      valid: false,
      showPass: false,
  
      newRes: {
        pattern: "",
        corpId: 0
      },
  
      patternList: [],
  
      corpList: [] as Corp[],
      extenList: [] as Extension[],
      rules: {
        required: (v: any) => !!v || i18n.t("loginView.required"),
        onlyTandNums: (v: string) => /^(?!.*T.*T)[0-9T]*$/.test(v) || i18n.t("rules.numberTDot")
      },
    }),
    methods: {
      ...mapActions({
        getCorpList: "crudService/getList",
        getDestinationGroup: "crudService/getList",
        newRestrictionExten: "crudService/newObject",
        updateRestrictionExten: "crudService/updateObject",
      }),
  
       resDetailHandler(res) {
        this.newRes.pattern = res.pattern;
        this.newRes.corpId = res.corpId;
      },
      async validate() {
        const isValid = await this.$refs.form.validate();
        if (isValid) {
            let newRes = {
            CorpId: this.newRes.corpId,
            Pattern: this.newRes.pattern,
          };
          let response;
          if (this.isToNew) {
            response = await this.newRestrictionExten({
              action: action.RESTRICTION_CORPIN_CONTROLLER,
              obj: newRes,
            });
          } else {
            response = await this.updateRestrictionExten({
              action: action.RESTRICTION_CORPIN_CONTROLLER,
              obj: {...newRes, Id: this.resToUpdate.id },
            });
          }
  
          // This set if the form must persist or don't
          this.dialog = response >= 400;
  
          this.alertMessage = msgCodes(response, this.isToNew).alertMessage;
          this.alertType = msgCodes(response, this.isToNew).alertType;
  
          this.$store.dispatch("snackbar/showSnack", {
            message: this.alertMessage,
            color: this.alertType,
            timeout: 3000,
          });
  
          this.$emit("refreshList", this.dialog)
        } 
      },
    },
  
    async beforeRouteEnter(to, from, next) {
      await store.dispatch("lookAndFeel/setCorpName");
      const corpName = store.state.lookAndFeel.corpName as string;
      if (!!to.params.exten) {
        next();
      } else {
        next({
          name: "extension",
          params: { corp: corpName, lang: navigator.language.slice(0, 2) },
        });
      }
    },
  
    async mounted() {
      if (!this.isToNew) {
        this.resDetailHandler(this.resToUpdate);
      }
      this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
    },
  });
  