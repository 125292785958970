
import Vue from "vue";
import axios from "axios";
import Top from "@/components/templates/Top.vue";

export default Vue.extend({
  name: "ClickToCall",
  components: {
    Top,
  },
  data() {
    return {
      clickToCall: {
        from: "",
        to: "",
        id: "",
      },
      formValid: false,
      rules: {
        required: (value) => !!value || this.$t("loginView.required"),
        isNumber: (value) => /^\d+$/.test(value) || this.$t("validation.isNumber"),
      },
    };
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form.validate()) {
        axios.post("url", this.clickToCall);
				this.$refs.form.reset();
        this.clickToCall = {
					from: "",
        	to: "",
        	id: "",
				}
      }
    },
  },
});
