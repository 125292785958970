
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import Vue from "vue";
import { Extension, View } from "@/types/conf";
import Top from "@/components/templates/Top.vue";
import ExtensionsDetail from "./ExtensionsDetail.vue";
import ExtensionsCreate from "./ExtensionsCSV.vue";
import Panel from "@/plugins/panelHub";
import msgCodes from "@/extendedFunctions/msgCodes";

export default Vue.extend({
  data() {
    return {
      canDelete: false,
      canCreate: false,
      canUpdate: false,
      computedItems: screen.width < 600 ? 1 : 10,
      computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,
      alertType: "",
      alertMessage: "",
      extenList: [] as Extension[],
      selectedItem: null as Extension | null,
      search: "",
      dialog: false,
      dialogDelete: false,
      loadingList: false,
      csvKey: 0
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      views: "auth/views",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor"
    }),
    computedTrunkList() {
      return this.extenList.map(el => ({
        ...el,
        enabled: el.enabled ? i18n.t("misc.yes") : i18n.t("misc.no"),
        spy: el.spy ? i18n.t("misc.yes") : i18n.t("misc.no"),
        callgroup: !!el.callgroup ? el.callgroup : ""
      }));
    },
    headers() {
      const baseHeaders = [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          width: "90px"
        },
        {
          text: "Features",
          value: "features",
          sortable: false,
          width: "120px"
        },
        {
          text: "",
          value: "info",
          sortable: false
        },
        {
          text: i18n.t("misc.num"),
          value: "number"
        },
        {
          text: i18n.t("misc.description"),
          value: "description"
        },
        {
          text: i18n.t("misc.user"),
          value: "userName"
        },
        {
          text: i18n.t("misc.group"),
          value: "callgroup"
        },
        {
          text: i18n.t("misc.trunk"),
          value: "trunkNumber"
        },
        {
          text: i18n.t("misc.enabled"),
          value: "enabled"
        }
      ];
      if (this.user?.level != 4) {
        baseHeaders.push({
          text: i18n.t("misc.spyEnabled"),
          value: "spy"
        });
      }
      if (this.user?.level == 4) {
        baseHeaders.push({
          text: i18n.t("misc.action"),
          value: "action"
        });
      }
      return baseHeaders;
    },
    actionList() {
      return [
        { text: this.$i18n.t("actions.touch"), value: "ring" },
        { text: this.$i18n.t("actions.touch"), value: "touch" },
        { text: this.$i18n.t("actions.followMe"), value: "followMe" },
        { text: this.$i18n.t("actions.voiceMail"), value: "voiceMail" },
        { text: this.$i18n.t("actions.voiceMail"), value: "voicemail" },
        { text: this.$i18n.t("actions.simTouch"), value: "simTouch" },
        { text: this.$i18n.t("actions.exTouch"), value: "exTouch" }
      ];
    }
  },
  async mounted() {
    this.csvKey += 1;
    this.loadingList = true;
    this.extenList = await this.getExtensionsList(action.EXTENSION_CONTROLLER);
    this.actionHandler();
    this.loadingList = false;
  },
  components: {
    CSV,
    CopyButton,
    Top,
    ExtensionsDetail,
    ExtensionsCreate
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    ...mapActions({
      getExtensionsList: "crudService/getList",
      deleteExtension: "crudService/deleteObject"
    }),
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
          width: "90px"
        },
        {
          text: "Features",
          value: "features",
          sortable: false,
          width: "120px"
        },
        { 
          text: "", 
          value: "info",
          sortable: false 
        },
        { 
          text: i18n.t("misc.num"),
          value: "number"
        },
        {
          text: i18n.t("misc.description"),
          value: "description",
        },
        {
          text: i18n.t("misc.user"),
          value: "userName",
        },
        {
          text: i18n.t("misc.group"),
          value: "callgroup",
        },
        {
          text: i18n.t("misc.trunk"),
          value: "trunkNumber",
        },
        {
          text: i18n.t("misc.enabled"),
          value: "enabled",
        },
        this.user?.level != 4 ?
        {
          text: i18n.t("misc.spyEnabled"),
          value: "spy",
        } : "",
        {
          text: i18n.t("misc.maxContacts"),
          value: "maxContacts",
        },
        this.user?.level == 4 ?
        {
          text: i18n.t("misc.action"),
          value: "action",
        } : "",
      ];
    },
    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.EXTENSION);
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },
    deleteItem(item: Extension) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      const response = await this.deleteExtension({
        action: action.EXTENSION_CONTROLLER,
        id: this.selectedItem!.id
      });
      this.dialogDelete = response >= 400;
      this.alertMessage = msgCodes(response, false).alertMessage;
      this.alertType = msgCodes(response, false).alertType;
      this.$store.dispatch('snackbar/showSnack', {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000
      });
      this.refreshList();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    navigateTo(exten: any = false, to: string = "") {
      switch (to) {
        case "f":
          this.$router.replace({
            name: "follow",
            params: { extenFeat: exten }
          });
          break;
        case "r":
          this.$router.replace({
            name: "restrictionsexten",
            params: { extenFeat: exten }
          });
          break;
        case "s":
          this.$router.replace({
            name: "speedDialExten",
            params: { extenFeat: exten }
          });
          break;
        case "u":
          this.$router.replace({
            name: "extension/update",
            params: { extenToUpdate: exten }
          });
          break;
        default:
          break;
      }
    },
    async refreshList() {
      this.loadingList = true;
      this.extenList = await this.getExtensionsList(action.EXTENSION_CONTROLLER);
      await Panel.client.invoke("GetAllExtensions", this.user.corpId);
      await Panel.client.invoke("GetAllChannels", this.user.corpId);
      this.actionHandler();
      this.loadingList = false;
    }
  }
});
