
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import Top from '@/components/templates/Top.vue';
  import CalendarDetail from './CalendarDetail.vue';
  import axios from 'axios';
  export default Vue.extend({
    name: 'InfoMeet',
    components: {
      Top,
      CalendarDetail,
    },
    props: ["showForm", "selectedEvent", "route"],
    data() {
      return {
        dialog: false,
        meetList: null,
        confirmDeleteDialog: false,
      };
    },
    computed: {
      ...mapGetters({
        corp: 'auth/corp',
        user: 'auth/user',
        softwareId: 'auth/softwareId',
        menuIconColor: "lookAndFeel/menuIconColor",
      }),
    },
    methods: {
      copyToClipboard(){
        return `https://meet.voipgroup.com/${this.softwareId}/${this.corp.id}/1/${this.selectedEvent.name}`
      },
      copyEvent() {
        const eventDetails = `
        ${this.$t('misc.name')}: ${this.selectedEvent.name}
        ${this.$t('misc.beginning')}: ${this.selectedEvent.start.replace('T', ' ')}
        ${this.$t('misc.end')}: ${this.selectedEvent.end.replace('T', ' ')}
        ${this.$t('misc.creator')}: ${this.selectedEvent.creator}
        ${this.$t('misc.link')}: https://meet.voipgroup.com/${this.softwareId}/${this.corp.id}/${this.selectedEvent.name}`;
        return eventDetails
      },
      returnRoute(){
        this.$router.push({
          name: this.route,
          params: { date: this.selectedEvent.date },
        });
      },
      async deleteEvent(event) {
        this.eventToDelete = event;
        this.confirmDeleteDialog = true;
      },
      async confirmDelete() {
        await axios.delete("http://177.11.49.135:4000/DateRooms/", {
          data: {
            corpId: this.corp.id,
            name: this.eventToDelete.name,
            softwareId: this.softwareId
          }
        });
        this.confirmDeleteDialog = false,
        this.dialog = false;
        this.showForm = false;
        this.$router.push({
          name: this.route
        });
      },

    },
  });
