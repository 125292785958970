
import msgCodes from '@/extendedFunctions/msgCodesExtenSerial';
import i18n from "@/plugins/i18n";
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { tokenHeader } from "../../../services/auth";
import axios from 'axios';
import * as action from '@/store/actions';
export default Vue.extend({
  data: () => ({
    dialog: false,
    file: null,
    csvPreview: [],
    valid: true,
    showDownloadErrorsButton: false,
    errorMessages: [],
    tableHeaders: [] as { text: string; value: string }[],
    tableRows: [] as any[],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      getUserList: 'crudService/getList',
    }),

    openDialog() {
      this.file = null;
      this.csvPreview = [];
      this.headers = [];
      this.errorMessages = [];
      this.showDownloadErrorsButton = false;
      this.$refs.form?.resetValidation();
      this.dialog = true;
    },

    handleFileUpload(event) {
      this.file = event;
      if (!this.file) {
        this.csvPreview = [];
        this.tableHeaders = [];
        this.tableRows = [];
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        let text = e.target?.result;
        if (text instanceof ArrayBuffer) {
          text = new TextDecoder("utf-8").decode(text);
        }
        if (typeof text === 'string' && text.trim().length > 0) {
          const rows = text.replace(/\r/g, '').split('\n').map(row => row.split(','));
          this.csvPreview = rows;
          this.tableHeaders = rows[0].map(header => ({ text: header, value: header }));
          this.tableRows = rows.slice(1).map(row => {
            const obj: Record<string, any> = {};
            this.tableHeaders.forEach((header, index) => {
              obj[header.value] = row[index] ? String(row[index]).trim() : "";
            });
            return obj;
          });
        } else {
          this.csvPreview = [];
          this.tableHeaders = [];
          this.tableRows = [];
        }
      };
      reader.readAsText(this.file);
    },

    saveCsvAsJson() {
      if (this.csvPreview.length < 2) return;

      const headers = this.csvPreview[0];
      const jsonData = this.csvPreview.slice(1)
        .map(row => {
          let obj: Record<string, any> = {};
          headers.forEach((header, index) => {
            let value = row[index]?.trim();
            obj[header.trim()] = value || null;
          });
          return obj;
        })
        .filter(obj => Object.values(obj).some(value => value !== null));

      if (jsonData.length === 0) {
        return;
      }

      const token = tokenHeader();
      axios.post(action.EXTENSIONSERIAL_CONTROLLER, jsonData, {
        headers: {
          Authorization: token.Authorization,
          'Content-Type': 'application/json',
        }
      })
        .then(() => {
          this.dialog = false;
          this.$emit('refreshList', this.dialog);
          this.$store.dispatch('snackbar/showSnack', {
            message: `${i18n.t('misc.created')} ${i18n.t('misc.defaultSuccess')}`,
            color: "success",
            timeout: 3000,
          });
        })
        .catch(error => {
          if (error.response) {
            const errorMessages = error.response.data.map((errorObj: { statusCode: number, content: any }) =>
              `Error en extensión ${errorObj.content}: ${msgCodes(errorObj.statusCode)}`
            );
            this.errorMessages = errorMessages;
            this.showDownloadErrorsButton = true;
          }
        });
    },

    downloadErrorFile() {
      const fileContent = this.errorMessages.join('\n');
      const encodedUri = encodeURI("data:text/plain;charset=utf-8," + fileContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "errores.txt");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.dialog = false;
    },
    downloadExampleCSV() {
      const csvConst = `Number,Pass,TrunkNumber,Outer,Enabled,Action,Onbusy,Description,Spy,CallLimit,PortType,Vmpass,Callgroup,UserName,MaxContacts
1000,securepassword,551131070003,123456789,1,ring,voicemail,Extensión de prueba 1,1,1,UDP,123,0,tiziano,1
1001,anotherpassword,551131070003,987654321,0,followMe,followMe,Extensión de prueba 2,0,5,WSS,1234,9,tiziano,5
1002,anotherpassword,551131070003,987654321,0,voicemail,exTouch,Extensión de prueba 2,0,5,WSS,1234,9,tiziano,5
1003,anotherpassword,551131070003,987654321,0,exTouch,voicemail,Extensión de prueba 2,0,5,WSS,1234,9,tiziano,5
1004,anotherpassword,551131070003,987654321,0,simTouch,voicemail,Extensión de prueba 2,0,5,WSS,1234,9,tiziano,5
1005,anotherpassword,,,0,ring,,,0,5,WSS,1234,,tiziano,5`;

      const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvConst);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      const fileName = this.$i18n.t('misc.exampleCSV') + ".csv";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
});
