
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { mainBus } from '@/main';
import * as action from '@/store/actions';

import { Corp, MyUser, View } from '@/types/conf';
import Panel from '@/plugins/panelHub';
import {
  eraseCookie,
  getCookie,
  setCookie,
} from '@/plugins/utils/cookieManager';

import NavGroup from './NavGroup.vue';
import DashboardListItem from './NavigationDrawerItem.vue';

export default Vue.component('NavigationDrawer', {
  components: {
    NavGroup,
    DashboardListItem
  },
  data: () => ({
    showNameCorp: !!getCookie('selectedCorpName'),
    nameCorp: '' as string | null,
    userIp: null,
    corpIp: null,
    // This controls the active sub-element in the screen.
    activeSubItem: '',
    menu: false,
    activeList: null,
    // This paint the selected item in the menu.
    selectedCorpActive: false,
    selectedCorp: false,
    dashboardActive: false,
    panelActive: false,
    statsActive: false,
    cdrActive: false,
    configActive: false,
    logmonActive: false,
    aboutActive: false,
    salasActive: false,
    confirmView: false,

    selectedCorpCollapsed: false,
    corpCollapsed: false,
    configCollapsed: false,
    statsCollapsed: false,
    panelCollapsed: false,

    loading: false,
    userTemp: null as MyUser | null,
    hoverColorLocal: '',
    menuIconColorLocal: '',
    menuFontColorLocal: '',
    loginBottomImageLocal: '',
    mini: true,
    corpList: [] as Corp[],
  }),

  computed: {
    ...mapGetters({
      user: 'auth/user',
      corp: 'auth/corp',
      softwareId: 'auth/softwareId',
      rooms: 'auth/rooms',
      hoverColor: 'lookAndFeel/hoverColor',
      menuIconColor: 'lookAndFeel/menuIconColor',
      menuFontColor: 'lookAndFeel/menuFontColor',
      loginBottomImage: 'lookAndFeel/loginBottomImage',
      menuColor: 'lookAndFeel/menuColor',
    }),

    computedHover(): string {
      return 'background-color: ' + this.hoverColorLocal;
    },
  },

  async mounted() {
    mainBus.$on(action.RELOAD_LOOK_AND_FEEL, () => {
      this.hoverColorLocal = this.hoverColor;
      this.menuIconColorLocal = this.menuIconColor;
      this.menuFontColorLocal = this.menuFontColor;
      this.loginBottomImageLocal = this.loginBottomImage;
    });

    if (this.user.level == 1)
      this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
  },

  created() {
    mainBus.$on(action.USER_LOADED, (user: MyUser) => {
      this.userTemp = user;
    });
  },

  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      getCorpList: 'crudService/getList',
    }),
    setActiveList(item) {
      this.activeList = this.activeList === item ? null : item;
    },
    collapseSubItems() {
      if (!this.mini) {
        if (this.configCollapsed) {
          this.configCollapsed = !this.configCollapsed;
        }
        if (this.statsCollapsed) {
          this.statsCollapsed = !this.statsCollapsed;
        }
        if (this.selectedCorp) {
          this.selectedCorp = !this.selectedCorp;
        }
      }
      this.mini = !this.mini;
    },

    async handleSignOut() {
      await Panel.stop();

      await this.signOut();
      eraseCookie('selectedCorpId');
    },
  },
  updated() {
    this.nameCorp = getCookie('selectedCorpName');
  },
});
