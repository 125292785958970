var ip = window.location.hostname;

export default {
  url: "http://" + ip + ":57073/api/v1/",
  panel: "http://" + ip + ":57072/panelHub",
  logmon: "http://" + ip + ":57072/panelHub?log=on",
  logmon45: "http://" + ip + ":8081/logmon.php",
  salasUrl: "http://" + ip + ":13000/api/",
  fail2ban: "https://" + ip + ":10000",
}
